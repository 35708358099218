import Golf6 from "../images/cars-big/golf6.jpg";
import AudiA1 from "../images/cars-big/audia1.jpg";
import Toyota from "../images/cars-big/toyotacamry.jpg";
import Bmw320 from "../images/cars-big/bmw320.jpg";
import Benz from "../images/cars-big/benz.jpg";
import Passat from "../images/cars-big/passatcc.jpg";
import Innova from "../images/Toyota-Innova-Crysta.png"

export const CAR_DATA = [
  [
    {
      img: "https://www.pngkey.com/png/full/254-2540155_swift-dzire-suzuki-swift-dzire-png.png",
      model1: "Maruti Shift Dzire",
      model2: "Toyota Etios",
      model3: "Honda City",
      model4: "Toyota Corolla Altis",
      model5: "Audi A4",
      model6: "Audi A6",
      model7: "Merecedes E-Class",
      model8: "Merecedese S-Class",
      bmw: "BMW",
    },
  ],
  [
    {
      img: "https://prestigetravelers.in/wp-content/uploads/2019/06/Tempo-Traveller-min.png",
      model1: "Tempo Traveller",
      model2: "Mercedes Sprinter",
      model3: "Toyota Coaster",
      model4: "Honda Hiace",
      model5: "Carvaa Travelers",
      model6: "Toyota Grand ",
      model7: "Motohom",
      model8: "Ecco Van",
      model9: "Omni Van",
      
    },
  ],
  [
    {
      img: "https://static.vecteezy.com/system/resources/previews/020/188/813/non_2x/toyota-innova-crysta-top-model-2393cc-automatic-transmission-turbo-engine-6-speed-gear-free-png.png",
      model1: "Toyota Innova Crysta",
      model2: "Toyota Fortuner",
      model3: "Toyota Innova Crysta",
      model4: "Tempo Traveller",
      model5: "Audi Q7",
      model6: "Bmw X Series",
      model7: "Toyota Prado",
      model8: "Mahindra Marazzo",
      model9: "Maruti XL6",
    },
  ],
  [
    {
      img: "https://i.pinimg.com/originals/56/1a/fe/561afeafc2b10e75c616b55341fa835d.png",
      model1: "18 Seater Coach",
      model2: "27 Seater Coach",
      model3: "35 Seater Coach",
      model4: "41 Seater Coach",
      model5: "41 Seater With Washroom",
      model6: "45 Seater Volvo",
      model7: "41 Seater Merecdes With Washroom",
      model8: "50 Seater Coach",
      model9: "51 Seater Coach",
    },
  ],
  [
    {
      img: "https://cdn.zeebiz.com/sites/default/files/styles/zeebiz_850x478/public/2018/10/10/55338-maruti-ciaz.png?itok=WQYlhQ8U",
      model1: "Suzuki Ciaz",
      model2: "Hyundai Verna",
      model3: "Honda Amaze",
      model4: "Hyundai Aura",
      model5: "Skoda Slavia",
      model6: "Audi A4",
      model7: "Tata Tigor",
      model8: "Hyndai Elantra",
      model9: "Volkswagen Vento",
    },
  ],
  [
    {
      img: "https://i.pinimg.com/originals/81/40/f5/8140f5896bad37c8e849910df8a8c5ba.png",
      model1: "Maruti Swift",
      model2: "Maruti Balena",
      model3: "Tata Tiago",
      model4: "Maruti Wagon R",
      model5: "Hyundai i20",
      model6: "Tata Alrtoz",
      model7: "Nissan Magnite",
      model8: "Maruti Celerio",
      model9: "Renault KWID",
    },
  ],
];
